$modal-title-font: 500 #{rem-calc(22)} / #{rem-calc(24)} $font-primary-medium;
$modal-title-font--large: 500 #{rem-calc(36)} / #{rem-calc(38)} $font-primary-medium;
$modal-title-margin: rem-calc(0 0 20);
$modal-title-margin--large: rem-calc(0 0 15);
$modal-body-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$modal-text-font-size: rem-calc(14);
$modal-text-color: color(text-secondary);
$modal-text-margin: rem-calc(0 0 30);
$modal-subtitle-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$modal-subtitle-font--large: $modal-subtitle-font;
$modal-subtitle-text-transform: uppercase;
$modal-subtitle-margin: rem-calc(30 0 0);
$modal-subtitle-margin--large: rem-calc(30 0 0);
$modal-image-content-padding: rem-calc(25 0 0);
$modal-image-content-padding--large: rem-calc(75 70 65 100);
$modal-close-icon-color: color(dark);
$modal-body-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$modal-image-padding: rem-calc(50 25 25);
$modal-wrapper-padding: rem-calc(70 15 25);
$modal-wrapper-padding--medium: rem-calc(70 15 25);
$modal-wrapper-padding--large: rem-calc(75 100);
$modal-wrapper-width--large: rem-calc(970);
$modal-message-font-weight: 400;
$modal-message-text-align: left;
$modal-message-color: color(organism-background);
$modal-title-text-align: left;

@import "@lora/05-components/modal";
