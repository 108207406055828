// Product badge title
@import "@root/05-components/product/product-badge";

$product-badge-title-background: color(primary);
$product-badge-title-text-decoration: none;
$product-badge-title-padding: rem-calc(3 10);
$product-badge-title-text-transform: uppercase;
$product-badge-title-font: 500 #{rem-calc(12)} / #{rem-calc(14)} $font-primary;
$product-badge-title-font--large: $product-badge-title-font;
$product-badge-title-margin: rem-calc(5 0);
$product-badge-title-margin--large: $product-badge-title-margin;
$product-badge-title-color: color(secondary);
$product-badge-image-width: rem-calc(125);
$product-badge-table-padding: rem-calc(0 10 0 0);
$product-badge-table-margin: rem-calc(10 0 15);
$product-badge-table-item-padding: rem-calc(10);
$product-badge-image-margin: rem-calc(0);
$product-badge-image-padding: rem-calc(0 15 0 0);

@mixin lora-components-product-badge {
    .c-minicart__items {
        padding: $product-badge-table-item-padding;

        .c-product-tile-horizontal__figure {
            @include breakpoint(medium) {
                width: $product-badge-image-width;
                margin: $product-badge-image-margin;
                padding: $product-badge-image-padding;
            }
        }

        .c-product-tile-horizontal__info-item {
            @include breakpoint(medium) {
                padding: $product-badge-table-padding;
            }
        }

        .c-product-tile-horizontal__variation {
            @include breakpoint(medium) {
                margin: $product-badge-table-margin;
            }
        }
    }
}