@import "@master/05-components/button";

$button-social-facebook-background: #3b5998;
$button-social-line-background: #06c755;
$button-social-twitter-background: #1da1f2;
$button-social-yahoo-background: #f03;

$button-social-icon-text-transform: uppercase;
$button-social-list: (
    facebook: (
        icon: facebook-social,
        background: $button-social-facebook-background,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    twitter: (
        icon: twitter-social,
        background: $button-social-twitter-background,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    instagram: (
        icon: instagram,
        background: color(primary)
    ),
    google: (
        icon: google,
        background: color(primary)
    ),
    linkedin: (
        icon: linkedin,
        background: color(primary)
    ),
    gmail: (
        icon: gmail,
        background: color(primary)
    ),
    pinterest: (
        icon: pinterest,
        background: color(primary)
    ),
    line: (
        icon: lineme,
        background: $button-social-line-background,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    yahoo: (
        icon: yahoo-social,
        background: $button-social-yahoo-background,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
);

$button-font-size: rem-calc(12);
$button-font-weight: 500;

/* Modal popup submit button style for smaller devices */
$button-submit-modal-width--medium-down: rem-calc(170);

@mixin lrpjp-components-button {
    .c-modal__wrapper {
        .c-button.c-form__submit {
            @include breakpoint(medium down) {
                width: auto;
                min-width: $button-submit-modal-width--medium-down;
                margin: 0 auto;
            }
        }
    }

    .page-autoreplenishment {
        .c-product-table-details .c-button[data-component-options*='解約'] {
            display: block;
        }
    }
}