$footer-links-color: color(organism-background);
$footer-links-font: 300 #{rem-calc(10)} / #{rem-calc(16)} $font-primary;
$footer-links-margin: rem-calc(30 0);
$footer-links-margin--large: $footer-links-margin;
$footer-links-divider-after-display: none;
$footer-links-hover-color: color(primary);
$footer-links-hover-text-decoration: none;
$footer-links-justify-content--large: flex-end;

@import "@lora/05-components/footer/footer-links";
