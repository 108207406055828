$form-text-field-font-size: rem-calc(14);
$form-text-field-font-weight: 500;
$form-text-field-font-weight-hover: $form-text-field-font-weight;
$form-text-field-color: color(text);
$form-text-field-color-hover: $form-text-field-color;
$form-text-field-border: 0;
$form-text-field-border-hover: $form-text-field-border;
$form-text-field-border-focus: $form-text-field-border;
$form-text-field-box-shadow: rem-calc(0 1 0 0) color(primary-active);
$form-text-field-box-shadow-hover: $form-text-field-box-shadow;
$form-text-field-counter-font-size: rem-calc(10);
$form-text-field-counter-color: color(text-secondary);
$form-text-field-box-shadow-focus: rem-calc(0 1 0 0) color(dark);
$form-text-field-padding: rem-calc(0 20 0 0);
$form-text-field-font-weight: 500;
$form-text-field-font-weight-hover: $form-text-field-font-weight;
$form-text-field-textarea-min-height: rem-calc(110);
$form-text-field-textarea-padding: rem-calc(16 20 0 0);

/// Requirements icon
$form-text-field-requirements-item-icon-color: color(element-background);
/// On error
$form-text-field-requirements-item-error-icon: 'error-icon';
/// On validate error
$form-text-field-requirements-on-validate-item-error-icon-color: color(element-background);

@import "@lora/05-components/form/text-field";
