$form-check-field-label-font-weight: 300;

$form-check-field-checkbox-control-border: 1px solid color(primary-active);
$form-check-field-disabled-control-background: color(light);
$form-check-field-checkbox-checked-icon: checkmark;
$form-check-field-checkbox-checked-control-background: color(light);
$form-check-field-checkbox-checked-control-border: 1px solid color(primary-active);
$form-check-field-checkbox-checked-icon-color: color(dark);
$form-check-field-checkbox-checked-icon-width: rem-calc(20);
$form-check-field-checkbox-control-background: none;
$form-check-field-checkbox-control-hover-box-shadow: none;
$form-check-field-checkbox-control-focus-box-shadow: none;
$form-check-field-checkbox-control-active-box-shadow: none;
$form-check-field-checkbox-checked-control-active-box-shadow: none;
$form-check-field-checkbox-checked-control-focus-box-shadow: none;
$form-check-field-checkbox-checked-control-hover-box-shadow: none;
$form-check-field-checkbox-checked-control-font-weight: 400;
$form-check-field-label-font-size: rem-calc(14);
$form-check-field-checkbox-control-margin-top: rem-calc(2);

//Radio Button
$form-check-field-radio-control-size: rem-calc(43);
$form-check-field-radio-control-background: color(dark);
$form-check-field-radio-control-border: #{$form-check-field-radio-control-size / 2} solid color(light);
$form-check-field-radio-control-after-border: 1px solid color(border);
$form-check-field-radio-control-after-left: 0;
$form-check-field-radio-control-after-size: rem-calc(20);
$form-check-field-radio-control-margin-top: rem-calc(-2);
$form-check-field-radio-control-margin-top--retina: $form-check-field-radio-control-margin-top;
$form-check-field-radio-control-after-top: 0;
$form-check-field-radio-control-hover-box-shadow: none;
$form-check-field-radio-control-focus-box-shadow: none;
$form-check-field-radio-control-active-box-shadow: none;
$form-check-field-radio-checked-control-border-width: #{$form-check-field-radio-control-size / 6};
$form-check-field-radio-checked-control-border-after: 1px solid color(dark);
$form-check-field-radio-checked-control-font-weight: 400;
$form-check-field-radio-checked-control-hover-box-shadow: none;
$form-check-field-radio-checked-control-focus-box-shadow: none;
$form-check-field-radio-checked-control-active-box-shadow: none;
$form-check-field-label-height: 1.75;
$form-check-field-radio-control-margin-top--retina: rem-calc(-2.2);
$form-check-field-radio-control-margin-top: rem-calc(-3);

/// Switch after
$form-check-field-switch-after-background: color(organism-background);
$form-check-field-switch-after-position--top: rem-calc(4);
$form-check-field-switch-after-position--right: rem-calc(26);

/// Checked Hover, Focus, Active states
$form-check-field-switch-checked-after-hover-box-shadow: none;
$form-check-field-switch-checked-after-focus-box-shadow: none;
$form-check-field-switch-checked-after-active-box-shadow: none;

/// Unchecked Hover, Focus, Active states
$form-check-field-switch-after-hover-box-shadow: none;
$form-check-field-switch-after-focus-box-shadow: none;
$form-check-field-switch-after-active-box-shadow: none;

/// Switch before
$form-check-field-switch-before-width: rem-calc(52);
$form-check-field-switch-before-height: rem-calc(30);
$form-check-field-switch-before-background: color(info);
$form-check-field-switch-before-border-radius: rem-calc(26);

// Checked state
$form-check-field-switch-checked-before-background: color(primary);
$form-check-field-switch-checked-after-background: color(secondary);
$form-check-field-switch-checked-after-position--right: rem-calc(4);

// Switch
$form-check-field-switch-padding: rem-calc(0 60 0 0);

@import "@lora/05-components/form/check-field";
