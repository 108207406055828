$header-navigation-container-content-padding: rem-calc(8 16 16);
$header-navigation-link-level-1-font-weight--large: 500;
$header-navigation-link-level-1-text-transform: uppercase;
$header-navigation-link-level-1-text-transform--large: $header-navigation-link-level-1-text-transform;
$header-navigation-item-title-level-1-font-size: rem-calc(12);
$header-navigation-link-level-1-font-family--large: $font-primary-medium;
$header-navigation-item-title-level-1-padding-left: rem-calc(16);
$header-navigation-item-title-level-1-expanded-background: color(secondary);
$header-navigation-item-title-level-2-padding-left: rem-calc(24);
$header-navigation-item-title-level-2-background: color(text-background);
$header-navigation-item-title-padding: rem-calc(15 0);
$header-navigation-link-hover-font-weight: normal;
$header-navigation-link-level-2-color: color(text);
$header-navigation-link-level-2-color--large: color(text);
$header-navigation-link-level-2-font-weight: 500;
$header-navigation-item-title-level-2-expanded-background: color(text-background);
$header-navigation-item-title-level-2-expanded-color: color(text-background);
$header-navigation-item-level-3-all-link-color: color(text-primary);
$header-navigation-item-level-3-all-link-font: 400 #{rem-calc(14)} / rem-calc(16.8) $font-primary;
$header-navigation-link-level-3-padding--medium-down: rem-calc(11.5 0 11.5 48);
$header-navigation-item-title-level-3-color: color(organism-background);
$header-navigation-item-level-3-border: none;
$header-navigation-locale-content-padding: rem-calc(20);
$header-navigation-flyout-element-background--large: color(light);
$header-navigation-link-level-2-font-size--large: rem-calc(14);
$header-navigation-link-level-2-font-weight--large: 500;
$header-navigation-item-level-2-all-link-font: #{rem-calc(14)} / rem-calc(16.8) $font-primary;
$header-navigation-item-level-2-all-link-text-transform: uppercase;
$header-navigation-item-level-2-all-link-text-decoration: underline;
$header-navigation-item-level-2-all-link-color: color(text);
$header-navigation-link-level-2-text-transform--large: uppercase;
$header-navigation-link-level-3-color--large: color(text-primary);
$header-navigation-item-level-3-all-link-color--large: color(text);
$header-navigation-link-level-3-font-weight--large: normal;
$header-navigation-link-level-3-hover-color: color(text-primary);
$header-navigation-link-level-3-hover-font-weight: normal;
$header-navigation-item-level-3-all-link-font--large: #{rem-calc(14)} / 1.43 $font-primary;
$header-navigation-item-level-3-all-link-text-decoration: underline;
$header-navigation-item-level-3-all-link-text-decoration--large: none;
$header-navigation-item-level-3-all-link-hover-color: color(primary);
$header-navigation-item-title-level-3-font-size--large: rem-calc(14);
$header-navigation-list-level-3-margin--large: rem-calc(6 0 0);
$header-navigation-content-font--large: 500 #{rem-calc(14)} / 1.43 $font-primary;
$header-navigation-content-hover-color--large: color(primary);
$header-navigation-content-hover-font--large: $header-navigation-content-font--large;
$header-navigation-link-level-1-padding--large: rem-calc(17 16);
$header-navigation-item-title-level-1-expanded-color: color(text);
$header-navigation-item-title-level-1-color: color(text-organism);
$header-navigation-item-title-level-1-expanded-font: 700 #{rem-calc(14)} / rem-calc(16.8) $font-primary;
$header-navigation-link-level-2-font-family: $font-primary;
$header-navigation-list-level-2-background: color(text-primary);
$header-navigation-item-title-level-1-font: #{rem-calc(14)} / rem-calc(16) $font-primary;
$header-navigation-item-title-level-3-background: color(text-background);
$header-navigation-link-level-3-color: color(text-primary);
$header-navigation-item-level-2-all-link-text-decoration: underline;
$header-navigation-item-level-2-all-link-text-transform: uppercase;
$header-navigation-item-title-level-2-font: #{rem-calc(14)} / rem-calc(16.8) $font-primary;
$header-navigation-item-title-level-2-expanded-font: 500 #{rem-calc(14)} / rem-calc(16.8) $font-primary;
$header-navigation-container-grid-list-level-2-padding--large: rem-calc(0 0 16);
$header-navigation-container-grid-padding--xlarge: rem-calc(24 0 0);
$header-navigation-item-level-3-all-link-color--large: color(text-primary);
$header-navigation-item-level-3-padding--large: rem-calc(6 0);
$header-navigation-item-level-2-padding--large: rem-calc(0 12);
$header-navigation-link-level-1-hover-color--large: color(text-organism);

@import "@lora/05-components/header/header-navigation";
