///Footer Contacts Title
$footer-contacts-title-color: color(text);
$footer-contacts-title-color--large: color(text);
$footer-contacts-title-text-transform: uppercase;
$footer-contacts-title-text-transform--large: uppercase;
$footer-contacts-title-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$footer-contacts-title-font--large: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$footer-contacts-title-margin: rem-calc(0 0 18 0);
$footer-contacts-title-margin--large: rem-calc(0 0 18 0);
$footer-contacts-text-align--medium-down: left;
///Footer Contacts Description
$footer-contacts-description-color: color(organism-background);
$footer-contacts-description-font: #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
///Footer Contacts Links
$footer-contacts-link-radius: 0;
$footer-contacts-link-background: color(secondary);
$footer-contacts-link-font: 500 #{rem-calc(12)} $font-primary;
$footer-contacts-link-color: color(text);
$footer-contacts-link-text-transform: uppercase;
$footer-contacts-link-height: rem-calc(50);
$footer-contacts-border-bottom: 0;
$footer-contacts-padding: rem-calc(10 0 0);
$footer-contacts-padding--large: rem-calc(16 0 20);
$footer-contacts-link-border: 1px solid color(border);
$footer-contacts-link-simple-hover-color: color(primary);
$footer-contacts-link-hover-background: color(secondary);
$footer-contacts-description-link-color: color(text-organism);
$footer-contacts-description-link-hover-color: color(primary);

@import "@lora/05-components/footer/footer-contacts";
