$alert-success: (
    background: rgba(color(success), 0.1),
    border: 1px solid color(success),
    color: color(dark),
    icon: "success",
    icon-color: color(success)
);

$alert-warning: (
    background: rgba(color(warning), 0.1),
    border: 1px solid color(warning),
    color: color(dark),
    icon: "alert",
    icon-color: color(primary)
);

$alert-error: (
    background: rgba(color(global-background), 1),
    border: 1px solid color(global-background),
    color: color(dark),
    icon: "error",
    icon-color: color(alert)
);
$alert-color: color(text-organism);
$alert-message-font: 500 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-medium;
$alert-icon-margin: rem-calc(-2 10 0 0);
$alert-border-radius: 0;
$alert-box-shadow: 0;

@import "@lora/05-components/alert";