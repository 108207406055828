$error-callout-text-align: left;
$error-callout-text-align--large: $error-callout-text-align;
$error-callout-title-color: color(light);
$error-callout-subtitle-color: color(light);
$error-callout-title-font: 500 #{rem-calc(22)} / rem-calc(24) $font-primary;
$error-callout-title-font--large: 500 #{rem-calc(36)} / rem-calc(38) $font-primary;
$error-callout-subtitle-font: 300 #{rem-calc(12)} / rem-calc(16) $font-secondary;
$error-callout-subtitle-font--large: 500 #{rem-calc(14)} / rem-calc(20) $font-secondary;
$error-callout-title-margin--large: rem-calc(0 0 8);
$error-callout-subtitle-margin: rem-calc(0 0 16);
$error-callout-subtitle-margin--large: $error-callout-subtitle-margin;
$error-callout-padding: rem-calc(40 0 0);
$error-callout-padding--large: rem-calc(40 0);
$error-callout-max-width: rem-calc(470);

@import "@lora/05-components/error-callout";