@import "@root/05-components/header/header-hamburger";

$header-hamburger-content-alignment--large: left;
$header-hamburger-asset-gift-collection-icon: gift !default;
$header-hamburger-asset-link-gift-collection-icon-size: rem-calc(30) !default;
$header-hamburger-asset-link-why-shop-icon: basket !default;
$header-hamburger-asset-link-why-shop-icon-size: rem-calc(30) !default;

@mixin lrpjp-components-hamburger {
    .c-hamburger__item.m-giftcollection .c-asset-link::before {
        @include svg-icon($header-hamburger-asset-gift-collection-icon, $header-hamburger-icon-color, $header-hamburger-asset-link-gift-collection-icon-size, $global-left center, no-repeat, $usesvg: false);
    }

    .c-hamburger__item.m-whyshop .c-asset-link::before {
        @include svg-icon($header-hamburger-asset-link-why-shop-icon, $header-hamburger-icon-color, $header-hamburger-asset-link-why-shop-icon-size, $global-left center, no-repeat, $usesvg: true);
    }
}
