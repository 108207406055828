$tag-link-background: color(secondary);
$tag-link-active-background: color(light);
$tag-link-active-border: 4px solid color(primary);
$tag-font: 500 #{rem-calc(12)} / rem-calc(16) $font-primary-medium;
$tag-link-color: color(organism-background);
$tag-link-active-color: color(organism-background);
$tag-padding: rem-calc(9 15);
$tag-link-active-box-shadow: rem-calc(2 2 5) color(border);
$tag-link-box-shadow: rem-calc(2 4 6) rgba(color(element-background), 0.2);
$tag-active-text-transform: uppercase;
$tag-link-border-width: rem-calc(0 0 0 4);
$tag-link-border: solid color(primary);
$tag-link-hover-background: color(disabled);
$tag-link-hover-color: color(organism-background);
$tag-link-active-border-width: rem-calc(0 0 0 4);

@import "@lora/05-components/tag";