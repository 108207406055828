$product-tile-horizontal-name-max-lines: 2;
$product-tile-horizontal-name-max-lines--large: 2;
$product-tile-horizontal-name-font-family: $font-primary-medium;
$product-tile-horizontal-name-link-color: color(dark);
$product-tile-horizontal-table-flex: 0 1 auto !default;
$product-tile-horizontal-table-margin: rem-calc(10 0 0) !default;
$product-tile-horizontal-name-link-text-transform: uppercase;
$product-tile-horizontal-name-margin--large: 0;
$product-tile-horizontal-figure-width: rem-calc(60);
$product-tile-horizontal-figure-width--large: rem-calc(170);
$product-tile-horizontal-figure-offset: rem-calc(10);
$product-tile-horizontal-figure-offset--large: rem-calc(30);
$product-tile-horizontal-info-item-flex-grow--large: 0;
$product-tile-horizontal-quantity-flex-grow--large: 1;
$product-tile-horizontal-info-item-padding--large: rem-calc(0 45 0 0);
$product-tile-horizontal-compact-figure-width: null !default;
$product-tile-horizontal-compact-figure-width--large: null !default;
$product-tile-horizontal-compact-name-margin--large: rem-calc(0 0 5);
$product-tile-horizontal-compact-pricepercapacity-font-size: rem-calc(11) !default;
$product-tile-horizontal-compact-info-margin: 0 !default;
$product-tile-horizontal-compact-info-item-padding: 0 !default;
$product-tile-horizontal-subtitle-font-weight: 300;
$product-tile-horizontal-subtitle-margin: rem-calc(0 0 15);
$product-tile-horizontal-subtitle-margin--large: rem-calc(0 0 65);
$product-tile-horizontal-secondary-figure-width: rem-calc(60);
$product-tile-horizontal-secondary-figure-width--large: rem-calc(170);
$product-tile-horizontal-actions-padding: rem-calc(10 0 0);
$product-tile-horizontal-actions-padding--large: 0;
$product-tile-horizontal-variations-padding: rem-calc(0 0 0 35);
$product-tile-horizontal-tertiary-subtitle-margin--large: rem-calc(0 0 16);
$product-tile-horizontal-variations-padding--large: rem-calc(0 0 0 10);
$product-tile-horizontal-info-margin--large: rem-calc(5 0 0);

@import "@lora/05-components/product/product-tile-horizontal";
