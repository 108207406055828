$header-hamburger-icon-color: color(dark);
$header-hamburger-button-icon-height: rem-calc(20);
$header-hamburger-button-icon-width: rem-calc(20);
$header-hamburger-button-padding: rem-calc(15 14);
$header-hamburger-item-color: color(organism-background);
$header-hamburger-item-padding: rem-calc(0 0 0 11);
$header-hamburger-item-link-color: color(organism-background);
$header-hamburger-item-link-transform: none;
$header-hamburger-item-link-font-weight: 300;
$header-hamburger-icon-margin: rem-calc(0 10 5 0);
$header-hamburger-account-menu-icon-size: rem-calc(30);
$header-hamburger-storeslink-icon-size: rem-calc(27);
$header-hamburger-asset-link-may-we-help-you-icon-size: rem-calc(22);
$header-hamburger-asset-link-why-shop-online-icon-size: rem-calc(25);
$header-hamburger-asset-link-why-shop-online-icon: whyshoponline;
$header-hamburger-button-active-padding: rem-calc(16.75);
$header-hamburger-localization-button-background: color(text);
$header-hamburger-button-active-color: color(dark);
$header-hamburger-localization-button-color: color(text);
$header-hamburger-localization-button-background: color(text-background);

@import "@lora/05-components/header/header-hamburger";
