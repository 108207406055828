$header-logo-max-width: rem-calc(160);
$header-logo-max-width--large: rem-calc(235);
$header-logo-padding--large: rem-calc(5 0 0);
$header-top-nav-left-padding: 0;
$header-top-nav-left-padding--large: rem-calc(40 0 30);
$header-top-nav-right-padding--large: rem-calc(36 0 30);
$header-top-nav-item-separator-color: transparent;
$header-top-nav-item-font-size--large: rem-calc(10);
$header-top-nav-link-font: 400 #{rem-calc(12)} / rem-calc(14.4) $font-primary;
$header-top-nav-item-color: color(text-primary);
$header-top-nav-item-font-weight: $header-top-nav-link-font;
$header-top-nav-item-margin--large: rem-calc(0 0 0 15);
$header-top-nav-item-padding--large: 0;
$header-top-nav-item-account-display--large: inline-flex;
$header-search-button-icon-color: color(dark);
$header-search-button-icon-color--large: $header-search-button-icon-color;
$header-search-button-icon-height: rem-calc(20);
$header-search-button-icon-width: rem-calc(20);
$header-search-button-icon-height--large: rem-calc(16);
$header-search-button-icon-width--large: rem-calc(16);
$header-bottom-background--large: color(light);
$header-search-button-background--large: color(text-background);
$header-search-button-padding--large: rem-calc(15 80 14.5 16);
$header-search-button-font: 300 #{rem-calc(14)} / rem-calc(16) $font-primary;
$header-search-button-color--large: color(text);
$header-search-button-icon-color: color(organism-background);
$header-search-button-background-hover--large: color(light);
$header-search-button-color-hover--large: $header-search-button-color--large;
$header-search-button-icon-color-hover--large: $header-search-button-icon-color;
$header-search-button-label-margin: rem-calc(0 0 0 8);
$header-search-button-text-transform: uppercase;
$header-search-button-opened-background: $header-search-button-background--large;
$header-search-button-opened-color: $header-search-button-color--large;
$header-search-button-border--large: 1px solid transparent;
$header-search-button-border-hover--large: 1px solid color(dark);
$header-search-button-active-border--large: 1px solid transparent;
$header-sticked-background: color(light);
$header-sticked-search-button-icon-color: color(dark);
$header-sticked-minicart-icon-color: color(dark);
$header-sticked-minicart-icon-width--large: rem-calc(25);
$header-sticked-minicart-icon-height--large: rem-calc(25);
$header-user-link-account-sticky-login-icon-color: color(dark);
$header-user-link-account-sticky-login-icon-hover-color: color(primary);
$header-user-link-account-sticky-login-icon-height: rem-calc(33);
$header-user-link-account-sticky-login-icon-width: rem-calc(31);
$header-sticked-minicart-link-margin--large: rem-calc(-5);
$header-sticked-top-nav-item-margin--large: rem-calc(0 0 0 10);
$header-top-nav-storeslink-padding: rem-calc(14 10);
$header-sticked-top-nav-item-level-1-max-width--large: rem-calc(150);
$header-top-nav-item-separator-display: none;
$header-is-full-width: true;
$header-hamburger-border-width--large: rem-calc(0 0 1 0);

@import "@lora/04-layout/header";
