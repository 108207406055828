// Minicart global style
$minicart-scrollable-max-height: rem-calc(490);

// Empty minicart
$minicart-empty-width: rem-calc(670);
$minicart-empty-content-width: 55%;
$minicart-empty-content-background: color(global-background);
$minicart-empty-content-padding: rem-calc(60);
$minicart-empty-recommendation-width: 45%;
$minicart-empty-recommendation-padding: rem-calc(20 20 40);
$minicart-empty-text-margin: rem-calc(0 0 30);
$minicart-empty-text-font: 500 #{rem-calc(22)} / #{rem-calc(24)} $font-primary;
$minicart-empty-checkout-link-font: #{rem-calc(14)} / 1.42 $font-primary;

// Table minicart
$minicart-background: color(light);
$minicart-th-padding: rem-calc(80 15 35 100);
$minicart-th-font: 500 #{rem-calc(22)} / 1.22 $font-primary;
$minicart-th-details-padding: rem-calc(80 15 35 100);
$minicart-th-details-background: color(light);
$minicart-th-price-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$minicart-th-qty-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$minicart-th-qty-padding: rem-calc(80 30 35 15);
$minicart-th-details-font: $minicart-th-font;
$minicart-th-price-padding: rem-calc(80 110 35 30);
$minicart-td-thumbnail-padding: rem-calc(0 40 55 110);
$minicart-td-padding: rem-calc(0 15 30);
$minicart-td-thumbnail-width: rem-calc(265);
$minicart-td-qty-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$minicart-td-price-text-align: right;
$minicart-td-price-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$minicart-td-price-padding: rem-calc(0 100 15 15);
$minicart-item-content-padding: rem-calc(25 10 15 10);

// Minicart Product
$minicart-product-title-margin: rem-calc(0 0 60);
$minicart-product-title-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$minicart-product-title-text-transform: uppercase;
$minicart-product-title-link-color: color(dark);

// Product Remove Icon
$minicart-remove-font: #{rem-calc(12)} / 1.5 $font-primary;
$minicart-remove-text-transform: capitalize;
$minicart-remove-icon-display: false;
$minicart-remove-margin: rem-calc(35 0 0);

// Minicart Subtotal
$minicart-subtotal-title-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$minicart-subtotal-title-text-transform: uppercase;
$minicart-subtotal-price-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$minicart-subtotal-background: color(light);
$minicart-subtotal-title-padding: rem-calc(15 30 15 100);
$minicart-subtotal-price-padding: rem-calc(15 100 15 15);

// Minicart promotions
$minicart-promotions-border: none;

// Minicart Actions
$minicart-header-border: rem-calc(1) solid color(border);
$minicart-footer-border: none;
$minicart-actions-padding: rem-calc(0 100 30);
$minicart-footer-content-background: color(global-background);
$minicart-actions-background: color(global-background);
$minicart-empty-background: color(global-background);

@import "@lora/05-components/minicart";
