$account-table-row-first-child-border-top: none;
$account-table-border-bottom: 1px solid color(border);
$account-table-cell-header-background: color(secondary-background);
$account-table-cell-header-padding--large: rem-calc(3 10);
$account-table-cell-header-color: color(text);
$account-table-cell-header-font--large: 500 #{rem-calc(14)} / rem-calc(20) $font-primary-medium;
$account-table-cell-header-font: 500 #{rem-calc(14)} / rem-calc(20) $font-primary-medium;
$account-table-cell-header-date-font--large: 500 #{rem-calc(14)} / rem-calc(20) $font-primary-medium;
$account-table-cell-header-date-font: 500 #{rem-calc(14)} / rem-calc(20) $font-primary-medium;
$account-table-cell-header-date-color: color(text);
$account-table-cell-status-font: 300 #{rem-calc(12)} / 1.34 $font-primary;
$account-table-cell-background: color(secondary);
$account-table-cell-background--large: $account-table-cell-background;
$account-table-cell-padding--large: rem-calc(3 12);
$account-table-cell-font--large: 300 #{rem-calc(14)} / rem-calc(20) $font-primary;
$account-table-cell-color--large: color(text-secondary);
$account-table-cell-font: 300 #{rem-calc(14)} / rem-calc(20) $font-primary;
$account-table-link-details-color: color(dark);
$account-table-cell-header-sort-current-icon-color: color(secondary-background);
$account-table-cell-header-sort-current-icon-background-color: color(secondary-background);
$account-table-cell-header-sort-icon-color: color(secondary-background);

//Hover state
$account-table-cell-background-hover: color(secondary);
$account-table-cell-color-hover: color(dark);
$account-table-row-box-shadow-hover: none;
$account-table-link-details-hover-color: color(light);

@import "@lora/05-components/account/account-table";
