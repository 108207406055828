$content-tile-title-margin: rem-calc(0 0 20);
$content-tile-title-margin--large: $content-tile-title-margin;
$content-tile-description-font: #{rem-calc(14)} / 1.43 $font-primary;
$content-tile-description-font--large: $content-tile-description-font;
/// Title Large
$content-tile-large-description-font: #{rem-calc(14)} / 1.25 $font-primary;
$content-tile-large-description-color: color(text-secondary);
$content-tile-large-title-font: 500 #{rem-calc(14)} / 1.25 $font-primary;
$content-tile-large-title-font--large: $content-tile-large-title-font;
$content-tile-large-title-text-transform--large: uppercase;
$content-tile-large-title-margin--large: rem-calc(0 0 18);
$content-tile-large-description-margin: rem-calc(0 0 36);
$content-tile-large-title-margin: rem-calc(0 0 18);
$content-tile-large-label-margin: rem-calc(0 0 16);
$content-tile-stack-gutter: rem-calc(14);
//Content Tag
$content-tile-large-label-border-left: 4px solid color(primary);
$content-tile-large-label-font--large: 300 #{rem-calc(12)} / rem-calc(16) $font-primary;
$content-tile-large-label-color: color(organism-background);
$content-tile-large-label-max-width: rem-calc(143);
$content-tile-large-label-padding: rem-calc(7 15 7 20);
$content-tile-large-label-box-shadow: rem-calc(2 2 5) color(border);
$content-tile-large-label-text-transform: uppercase;

/// Label
$content-tile-label-color: color(dark);
$content-tile-label-text-transform: uppercase;
$content-tile-label-text-decoration: underline;
$content-tile-label-font: #{rem-calc(14)} / 1.25 $font-primary;
$content-tile-label-margin: rem-calc(0 0 20);
$content-tile-large-label-text-decoration: none;

/// Tile Featured
$content-tile-featured-title-font: 500 #{rem-calc(14)} / rem-calc(20) $font-primary-medium;

/// Description
$content-tile-description-color: color(organism-background);

@import "@lora/05-components/content-tile";
