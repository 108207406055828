$section-title-font: 500 #{rem-calc(22)} / rem-calc(24) $font-primary;
$section-title-font--large: 500 #{rem-calc(36)} / rem-calc(38) $font-primary;
$section-title-text-align--large: left;
$section-content-margin: rem-calc(5 0 0);
$section-title-margin: rem-calc(26 0 10);
$section-view-more-link-font: #{rem-calc(12)} / 1.34 $font-primary;
$section-view-more-link-font--large: #{rem-calc(14)} / 1.43 $font-primary;
$section-view-more-margin--large: rem-calc(0 0 10);
$section-content-margin--large: rem-calc(20 0 0);
$section-secondary-title-font: 500 #{rem-calc(14)} / 1.43 $font-primary;
$section-description-font: #{rem-calc(14)} / 1.5 $font-primary;
$section-description-color: color(organism-background);
$section-description-text-align--large: left;
$section-headings-max-width--large: auto;

@import "@lora/05-components/section";
