$locale-selector-select-secondary-color: color(organism-background);
$locale-selector-select-secondary-font: 300 #{rem-calc(10)} / #{rem-calc(16)} $font-primary;
$locale-selector-select-secondary-arrow-color: color(text);
$locale-selector-select-arrow-size: 0;
$locale-selector-select-padding--large: rem-calc(0);
$locale-selector-select-secondary-arrow-height: rem-calc(6);
$locale-selector-select-secondary-arrow-width: $locale-selector-select-secondary-arrow-height;
$locale-selector-select-secondary-padding--large: rem-calc(16 30 16 20);
$locale-selector-select-background--large: color(light);
$locale-selector-select-secondary-arrow-position: rem-calc(15);
$locale-selector-select-font--large: 300 #{rem-calc(10)} / #{rem-calc(16)} $font-primary;
$locale-selector-select-hover-border: 1px solid color(light);
$locale-selector-select-secondary-font--large: 500 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;

@import "@lora/05-components/locale-selector";
