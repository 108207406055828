
                            $is-app_ng_lrp_jp-enabled: true;
                            $footer-sticky-image-wrapper-z-index: 10 !default;
$footer-sticky-image-wrapper-text-align: center !default;
$footer-sticky-image-wrapper-display: block !default;
$footer-sticky-image-wrapper-height: rem-calc(37) !default;
$footer-sticky-image-wrapper-padding: rem-calc(10) !default;
$footer-sticky-image-link-text-decoration: underline !default;

@mixin jplayer-components-footer-sticky-image {
    .c-footer-sticky-image__wrapper {
        position: fixed;
        width: 100%;
        height: $footer-sticky-image-wrapper-height;
        bottom: 0;
        z-index: $footer-sticky-image-wrapper-z-index;
        text-align: $footer-sticky-image-wrapper-text-align;
        background: color(primary);
        display: $footer-sticky-image-wrapper-display;
        padding: $footer-sticky-image-wrapper-padding;

        .c-footer-sticky-image__link-text {
            text-decoration: $footer-sticky-image-link-text-decoration;
            color: color(secondary);

            &:hover {
                text-decoration: none;
            }
        }
    }
}
                        