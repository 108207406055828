$stepper-input-field-box-shadow: none;

// Input field
$stepper-input-field-font-weight: 300;
$stepper-input-field-font-size: rem-calc(12);
$stepper-input-width: rem-calc(130);
$stepper-input-height: rem-calc(50);
$stepper-input-border: 1px solid color(border);
$stepper-input-hover-border-color: 2px solid color(dark);
$stepper-input-hover-border-color: color(dark);
$stepper-input-field-hover-color: color(primary);
$stepper-input-width--large: rem-calc(100);
$stepper-input-font: rem-calc(16) $font-primary;

@import "@lora/05-components/stepper-input";
