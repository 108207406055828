$header-user-menu-title-flex-direction: row;
$header-user-menu-title-margin: rem-calc(0 5 0 0);
$header-user-menu-justify-content: left;
$header-user-menu-font: normal #{rem-calc(14)} / 1.43 $font-primary;
$header-user-menu-logout-link-height: rem-calc(20);
$header-user-menu-logout-link-width: rem-calc(20);
$header-user-menu-padding: rem-calc(16);
$header-user-menu-back-button-height: rem-calc(40);
$header-user-menu-back-button-font: #{rem-calc(12)} / 1.34 $font-primary;
$header-user-menu-back-button-color: color(organism-background);
$header-user-menu-content-title-font-size: rem-calc(14);
$header-user-menu-content-title-text-transform--medium-down: uppercase;
$header-user-menu-content-title-font-weight--medium-down: 500;
$header-user-menu-secondary-list-margin: rem-calc(40 60 30);
$header-user-menu-secondary-list-border-top: 1px solid color(border);
$header-user-menu-secondary-link-height: rem-calc(50);
$header-user-menu-secondary-link-font-size: rem-calc(12);
$header-user-menu-secondary-link-font-weight: 300;
$header-user-menu-content-link-font-size: rem-calc(12);
$header-user-menu-content-link-color: color(organism-background);
$header-user-menu-content-link-logout-background: color(primary);
$header-user-menu-content-link-logout-border: 1px solid transparent;
$header-user-menu-content-link-logout-hover-background: color(light);
$header-user-menu-content-link-logout-hover-color: color(text);
$header-user-menu-content-link-logout-hover-border: 1px solid color(border);
$header-user-menu-content-link-logout-text-transform: uppercase;
$header-user-menu-content-link-logout-font-weight: 500;
$header-user-menu-secondary-logout-icon-height: rem-calc(20);
$header-user-menu-secondary-logout-icon-width: rem-calc(139);
$header-user-menu-secondary-logout-icon-size: rem-calc(20);
$header-user-menu-content-link-logout-margin: rem-calc(20 20 0);
$header-user-menu-content-link-logout-margin--large: rem-calc(10 0 0);
$header-user-menu-secondary-link-padding: rem-calc(10 20);
$header-user-menu-background: color(primary);
$header-user-menu-sign-in-color: color(secondary);
$header-user-menu-icon-color: color(secondary);
$header-user-menu-title-name-link-color: color(secondary);
$header-user-menu-title-color: color(secondary);
$header-user-menu-logout-link-color: color(secondary);

@import "@lora/05-components/header/header-user-menu";
