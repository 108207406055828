$tabs-tab-font: 300 #{rem-calc(14)} / 1.43 $font-primary;
$tabs-tab-font--large: #{rem-calc(22)} / rem-calc(24) $font-primary;
$tabs-tab-text-transform: uppercase;
$tabs-tab-letter-spacing: rem-calc(0.3);
$tabs-tab-letter-spacing--large: rem-calc(0.25);
$tabs-tab-margin: 0;
$tabs-tab-margin--large: $tabs-tab-margin;
$tabs-tab-after-border-bottom: 1px solid color(disabled);
$tabs-tab-after-width: 100%;
$tabs-tab-active-font: 500 #{rem-calc(14)} / 1.43 $font-primary;
$tabs-tab-active-font--large: 500 #{rem-calc(22)} / rem-calc(24) $font-primary;
$tabs-tab-active-color: color(text);
$tabs-tab-active-after-border-bottom: 1px solid color(text);
$tabs-tab-padding: rem-calc(0 34 15 0);
$tabs-tab-padding--large: rem-calc(15 130 15 0);
$tabs-controls-align-self: flex-start;
$tabs-tertiary-controls-background-color: color(transparent);
$tabs-content-margin: rem-calc(40 0 0);
$tabs-content-margin--large: rem-calc(34 0 0);
$tabs-tertiary-tab-color: color(text-secondary);
$tabs-tertiary-tab-font: 300 #{rem-calc(14)} / 1.43 $font-primary;
$tabs-tertiary-tab-active-color: color(dark);
$tabs-tertiary-tab-active-font-weight: 500;
$tabs-tertiary-tab-text-align: left;
$tabs-tertiary-tab-padding: rem-calc(15 10 15 0);

$tabs-secondary-controls-background: color(secondary);
$tabs-secondary-tab-font-size: rem-calc(14);
$tabs-secondary-tab-font-size--large: $tabs-secondary-tab-font-size;
$tabs-secondary-tab-font-weight: 300;
$tabs-secondary-tab-font-weight--large: $tabs-secondary-tab-font-weight;
$tabs-secondary-tab-active-font: 500 #{rem-calc(14)} / 1.43 $font-primary;
$tabs-secondary-tab-active-font-weight--large: 500;
$tabs-secondary-tab-padding: rem-calc(19 40 11 0);
$tabs-secondary-tab-padding--large: rem-calc(11 80 15 0);
$tabs-secondary-content-font: 300 #{rem-calc(12)} / 1.34 $font-primary;
$tabs-secondary-content-font--large: #{rem-calc(14)} / 1.43 $font-primary;
$tabs-secondary-content-background: color(secondary);
$tabs-secondary-content-color: color(text-secondary);
$tabs-secondary-content-color--large: color(organism-background);
$tabs-control-items-margin: 0;

@import "@lora/05-components/tabs";
