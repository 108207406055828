$product-bundle-title-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$product-bundle-title-qty-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$product-bundle-item-padding: rem-calc(40 0);
$product-bundle-title-text-transform: none;
$product-bundle-title-border-bottom: none;

// Plus icon
$product-bundle-icon-color: color(text);
$product-bundle-icon-size--large: rem-calc(48);
$product-bundle-icon-size: rem-calc(28);

@import "@lora/05-components/product/product-bundle";
