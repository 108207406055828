$product-tile-default-text-align: left;
$product-tile-rating-align: left;
$product-tile-rating-align--large: left;
$product-tile-info-item-align: left;
$product-tile-info-item-align--large: left;
$product-tile-add-bag-button-min-width: 100%;
$product-tile-add-bag-button-min-width--large: 100%;
$product-tile-variations-width--large: 100%;
$product-tile-name-font-size: rem-calc(14);
$product-tile-name-font-size--large: rem-calc(14);
$product-tile-name-font-family: $font-primary-medium;
$product-tile-name-line-height: rem-calc(16);
$product-tile-name-text-transform: uppercase;
$product-tile-name-line-height: 1.25;
$product-tile-name-color: color(text);
$product-tile-name-color-hover: color(primary);
$product-tile-price-font: 500 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$product-tile-price-font--large: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-tile-price-padding--large: rem-calc(5 0 0);
$product-tile-swatch-selected-color: color(organism-background);
$product-tile-swatch-selected-font-size: rem-calc(12);
$product-tile-swatch-selected-font-weight: 300;
$product-tile-description-line-height: 1;
$product-tile-description-margin: rem-calc(6 0);
$product-tile-description-margin--large: rem-calc(6 20 6 0);
$product-tile-description-font-weight: 300;
$product-tile-price-capacity-margin--large: rem-calc(16 0 0);
$product-tile-price-capacity-min-height--large: rem-calc(16 0 0);
$product-tile-secondary-border: 0;
$product-tile-secondary-border--large: $product-tile-secondary-border;
$product-tile-secondary-actions-margin: rem-calc(20 -12 0);
$product-tile-secondary-actions-margin--large: rem-calc(60 -12 0);
$product-tile-grid-list-align-items: flex-start;
$product-tile-grid-name-text-align: left;
$product-tile-grid-description-text-align: left;
$product-tile-grid-info-item-align-self: flex-start;
$product-tile-swatch-group-padding: rem-calc(0 30);
$product-tile-swatch-group-padding--large: rem-calc(0 25);
$product-tile-name-margin--large: rem-calc(0 20 8 0);
$product-tile-info-margin--large: rem-calc(0 20 0 0);
$product-tile-variations-single-font-size: rem-calc(14);
$product-tile-variations-single-font-weight: 500;
$product-tile-variations-single-text-align: center;
$product-tile-add-bag-text-transform: uppercase;
$product-tile-name-color-visited: color(text);
$product-tile-actions-margin: rem-calc(12) 0 0;

// Product rating star
$product-tile-rating-star-margin: 0 0 rem-calc(3 4);

@import "@lora/05-components/product/product-tile";
