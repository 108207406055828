$form-label-float-left: 0;
$form-label-float-padding: $form-label-float-left;
$form-label-color: color(organism-background);
$form-label-float-color: $form-label-color;
$form-label-float-font-size: rem-calc(14);
$form-label-float-focus-font-size: rem-calc(10);
$form-label-float-focus-font-weight: 300;
$form-label-float-focus-color: $form-label-color;
$form-label-float-focus-padding: 0;
$form-label-font-weight: 300;
$form-label-font-size: rem-calc(14);

@import "@lora/05-components/form/label";
