@import '@root/05-components/checkout/checkout-payment';

@mixin lrpjp-components-checkout-payment {
    .f_field_checkbox .agree-site-terms-checkbox + .f_label { // stylelint-disable-line selector-class-pattern
        display: inline;

        &::before {
            left: 0;
        }
    }
}