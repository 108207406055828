$footer-background: color(global-background);
$footer-top-background: color(primary);
$footer-middle-inner-padding--large: rem-calc(20 0 40);
$footer-error-color: color(highlight);

///Footer Newsletter
$footer-newsletter-captcha-text-color: color(organism-background);
$footer-newsletter-captcha-text-color--large: $footer-newsletter-captcha-text-color;
$footer-newsletter-captcha-text-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$footer-newsletter-captcha-link-color: color(text);
$footer-newsletter-captcha-link-color--large: $footer-newsletter-captcha-link-color;
$footer-newsletter-captcha-link-font: #{rem-calc(12)} / #{rem-calc(18)} $font-secondary;
$footer-newsletter-color: color(organism-background);
$footer-newsletter-color--large: $footer-newsletter-color;
$footer-newsletter-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$footer-newsletter-link-color: color(text);
$footer-newsletter-link-color--large: $footer-newsletter-link-color;
$footer-newsletter-link-font: #{rem-calc(12)} / #{rem-calc(18)} $font-secondary;
$footer-newsletter-signup-title-color: color(text);
$footer-newsletter-signup-title-display--large: none;
$footer-newsletter-signup-title-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$footer-newsletter-link-hover-color: color(primary);
$footer-newsletter-captcha-link-hover-color: color(primary);
$footer-newsletter-signup-title-text-transform: uppercase;

///Footer Bottom
$footer-bottom-border-top: 0;
$footer-bottom-border-top--large: $footer-bottom-border-top;
$footer-bottom-inner-border-top--large: 1px solid color(disabled);
///Footer Social Global
$footer-social-margin: 0;
$footer-social-margin--large: rem-calc(20 0);

@import "@lora/04-layout/footer";
