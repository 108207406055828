@import '@root/05-components/checkout/checkout-common';

/* stylelint-disable */
.checkout_step_signin .checkout_section_title::before {
    counter-increment: section !important;
    content: counter(section)"." !important;
}

.checkout_step_signin .checkout_section.checkout_static_steps {
    counter-reset: section !important;
}

.chmodule_samples {
    .f_field_samples_category {
        .f_label,
        .select_submit_action {
            display: none;
        }
    }
}

.checkout_section {
    &.completed {

        .chmodule_billingaddress,
        .chmodule_shippingaddress {
            display: inline-block;
            width: 45%;
        }
    }
}

.chmodule_shippingmethod {

    .checkout_module_body,
    .hazmat_message {
        width: 100%;
        float: left;

        .required {
            .f_label {

                .description,
                .price {
                    width: auto;
                }
            }
        }
    }

    .name,
    .price {
        @include breakpoint(medium down) {
            width: 45%;
            display: inline;
        }
    }
}
.order_totals_table .c-order-details-summary-table__label {
    display: block;
    text-align: left;
}
