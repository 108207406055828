$footer-social-title-color: color(text);
$footer-social-title-text-transform: uppercase;
$footer-social-title-text-transform--large: uppercase;
$footer-social-title-letter-spacing: rem-calc(0.26);
$footer-social-title-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$footer-social-link-color: color(primary);
$footer-social-link-border-radius: 50%;
$footer-social-link-border: 1px solid color(border);
$footer-social-link-hover-border: 1px solid color(primary);
$footer-social-link-background: color(light);
$footer-social-link-hover-color: color(primary);
$footer-social-link-padding: rem-calc(6);
$footer-social-link-padding--large: $footer-social-link-padding;
$footer-social-list-margin--large: rem-calc(15 0 0 0);
$footer-social-list-margin: rem-calc(10 0);
$footer-social-list: (
    facebook: (
        enabled: true,
        icon: facebook-default,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    twitter: (
        enabled: true,
        icon: twitter-default,
        icon-height: rem-calc(14),
        icon-width: rem-calc(16)
    ),
    google: (
        enabled: true,
        icon: google-default,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    pinterest: (
        enabled: true,
        icon: pinterest-default,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    instagram: (
        enabled: true,
        icon: instagram,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    youtube: (
        enabled: true,
        icon: youtube,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
);

@import "@lora/05-components/footer/footer-social";
