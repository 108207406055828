/// Nav Level 1
$footer-nav-link-level1-color: color(organism-background);
$footer-nav-list-level1-margin: rem-calc(24 0 16);
$footer-nav-link-level1-color--large: color(organism-background);
$footer-nav-link-level1-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$footer-nav-link-level1-font--large: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$footer-nav-link-level1-text-transform--large: uppercase;
$footer-nav-link-level1-letter-spacing: rem-calc(0.3);
$footer-nav-link-level1-hover-text-decoration: none;
$footer-nav-link-level1-hover-color: color(primary);
/// Nav Level 2
$footer-nav-link-level2-color: color(organism-background);
$footer-nav-link-level2-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$footer-nav-link-level2-font--large: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$footer-nav-link-level1-text-decoration: none;
$footer-nav-link-level1-text-decoration--large: $footer-nav-link-level1-text-decoration;
$footer-nav-link-level2-text-decoration: none;
$footer-nav-link-level2-text-decoration--large: $footer-nav-link-level2-text-decoration;
$footer-nav-list-level2-margin: rem-calc(16 0 0);
$footer-nav-list-level2-margin--large: $footer-nav-list-level2-margin;
$footer-nav-link-level2-padding: rem-calc(3 0);
$footer-nav-link-level2-hover-color: color(primary);
$footer-nav-link-level2-hover-text-decoration: none;
///FOOTER NAVIGATION
$footer-nav-columns: 6 of 12;
$footer-nav-columns--large: 3 of 12;

@import "@lora/05-components/footer/footer-nav";
