$header-suggestions-title-font: 500 #{rem-calc(14)} / 1.43 $font-primary;
$header-suggestions-title-font--large: $header-suggestions-title-font;
$header-suggestions-title-text-transform: uppercase;
$header-suggestions-title-padding: rem-calc(30 0 10);
$header-suggestions-text-color: color(text-secondary);
$header-suggestions-text-height: rem-calc(35);
$header-suggestions-text-height--large: rem-calc(69);
$header-suggestions-text-match-font: 500 #{rem-calc(14)} / 1.43 $font-primary;
$header-suggestions-text-match-font--large: $header-suggestions-text-match-font;
$header-suggestions-text-font: #{rem-calc(14)} / 1.43 $font-secondary;
$header-suggestions-text-font--large: $header-suggestions-text-font;
$header-suggestions-text-link-padding: rem-calc(0 0 0 20);
$header-suggestions-text-list-padding: rem-calc(0 0 20);
$header-suggestions-text-list-padding--large: 0;
$header-suggestions-text-list-separator: 1px solid color(border);
$header-suggestions-text-list-separator--large: none;
$header-suggestions-text-separator: none;
$header-suggestions-text-separator--large: 1px solid color(border);
$header-suggestions-products-height: rem-calc(60);
$header-suggestions-products-padding--large: rem-calc(25 0 0);
$header-suggestions-products-separator: none;
$header-suggestions-products-list-padding: rem-calc(0 0 10);
$header-suggestions-products-list-separator: 1px solid color(border);
$header-suggestions-products-list-separator--large: none;
$header-suggestions-products-image-margin: rem-calc(0 20 0 0);
$header-suggestions-products-name-font--large: 500 #{rem-calc(14)} / 1.43 $font-primary;
$header-suggestions-products-name-text-transform--large: uppercase;
$header-suggestions-products-description-font: 300 #{rem-calc(12)} / 1.34 $font-primary;
$header-suggestions-products-price-font: 500 #{rem-calc(14)} / 1.43 $font-primary;
$header-suggestions-content-only-padding--large: rem-calc(25 0 75);
$header-suggestions-content-image-width: rem-calc(70);
$header-suggestions-content-name-font--large: #{rem-calc(14)} / 1.43 $font-primary;
$header-suggestions-view-all-color: color(text);
$header-suggestions-view-all-font: #{rem-calc(12)} / 1.34 $font-primary;
$header-suggestions-view-all-font--large: $header-suggestions-view-all-font;
$header-suggestions-view-all-text-transform: none;
$header-suggestions-view-all-text-transform--large: $header-suggestions-view-all-text-transform;
$header-suggestions-aside-title-color: color(organism-background);
$header-suggestions-aside-title-font: 500 #{rem-calc(12)} / 1.34 $font-primary;
$header-suggestions-aside-title-margin--large: rem-calc(0 0 15);
$header-suggestions-aside-title-text-transform: uppercase;
$header-suggestions-aside-list-margin--large: rem-calc(0 0 25 10);
$header-suggestions-aside-link-color: color(organism-background);
$header-suggestions-aside-link-font: 300 #{rem-calc(12)} / 1.34 $font-primary;
$header-suggestions-aside-link-padding--large: rem-calc(0 0 10);
$header-suggestions-aside-margin: rem-calc(30 0 15 0);

@import "@lora/05-components/header/header-suggestions";
