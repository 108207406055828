@import "@root/05-components/account/account-table";

/* stylelint-disable selector-id-pattern, selector-max-id, selector-max-compound-selectors, declaration-no-important, max-nesting-depth */
#orderslist .c-account-table.m-hover .c-account-table__row {
    @include breakpoint(medium down) {
        cursor: auto;

        &:hover {
            box-shadow: $account-table-row-box-shadow-hover;

            .c-account-table__cell {
                background: none !important;
                text-shadow: none !important;
                color: inherit !important;
            }
        }
    }
}